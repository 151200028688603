import { filter } from 'lodash';

export const listBox = [
    {
        id: 1,
        img: 'silver.png',
        // img: 'gold.png',
        name: 'Silver Box',
        short: 'silver',
        price: 12000,
        usd: 120,
        hero: ['common', 'rare'],
        detail: 'The box containing "Common" and "Rare" characters and skins The percentage to own the characters and the "Common" skins is highest in this box',
    },
    {
        id: 2,
        img: 'gold.png',
        name: 'Gold Box',
        short: 'gold',
        price: 12000,
        usd: 120,
        hero: ['common', 'rare', 'epic'],
        detail: 'The box containing "Common", "Rare" and "Epic" characters and skins The percentage to own the "Rare" characters is highest in this box',
    },
    {
        id: 3,
        img: 'platinum.png',
        name: 'Platinum Box',
        short: 'platinum',
        price: 12000,
        usd: 120,
        hero: ['rare', 'epic', 'legend'],
        detail: 'The box containing "Rare" , "Epic" and "Legend" characters and skins The percentage to own the "Epic" characters is highest in this box',
    },
    {
        id: 4,
        img: 'diamond.png',
        name: 'Diamond Box',
        short: 'diamond',
        price: 12000,
        usd: 120,
        hero: ['epic', 'legend'],
        detail: 'The box containing "Epic" and "Legend" characters and skins With the rate of getting the "Legendary" characters up to 7️⃣0️⃣%',
    },
];
// ['common','rare','epic','legend']
export const BoxRare = [
    {
        id: 1,
        origin: ['66.50%', '3.50%', '0%', '0%'],
        chromas: ['27.55%', '1.45%', '0%', '0%'],
        prestige: ['0.95%', '0.05%', '0%', '0%'],
    },
    {
        id: 2,
        origin: ['17.50%', '49.00%', '3.50%', '0%'],
        chromas: ['7.25%', '20.30%', '1.45%', '0%'],
        prestige: ['0.25%', '0.70%', '0.05%', '0%'],
    },
    {
        id: 3,
        origin: ['0%', '17.50%', '49.00%', '3.50%'],
        chromas: ['0%', '7.25%', '20.30%', '1.45%'],
        prestige: ['0%', '0.25%', '0.70%', '0.05%'],
    },
    {
        id: 4,
        origin: ['0%', '0%', '49.00%', '21.00%'],
        chromas: ['0%', '0%', '20.30%', '8.70%'],
        prestige: ['0%', '0%', '0.70%', '0.30%'],
    },
];
export const RareOfBox = (id) => {
    return filter(BoxRare, (item) => {
        return item.id === id;
    });
};
export const Item = (short) => {
    return filter(listBox, (item) => {
        return item.short === short;
    });
};
