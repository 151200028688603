<script>
  import VueSlickCarousel from 'vue-slick-carousel';
  import 'vue-slick-carousel/dist/vue-slick-carousel.css';
  // optional style for arrows & dots
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
  import { listBox } from './box';
  import { mapGetters, mapMutations } from 'vuex';

  export default {
    components: { VueSlickCarousel },
    data() {
      return {
        settings: {
          dots: false,
          dotsClass: 'slick-dots custom-dot-class',
          edgeFriction: 0.35,
          infinite: true,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 4000,
          center: true,
          responsive: [
            // {
            //   breakpoint: 1400,
            //   settings: {
            //     slidesToShow: 3,
            //     slidesToScroll: 1,
            //   },
            // },
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        },
        settings: {
          dots: false,
          dotsClass: 'slick-dots custom-dot-class',
          edgeFriction: 0.35,
          infinite: true,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 4000,
          center: true,
          responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        },
        tournamentSelect: {},
        name: '',
      };
    },
    computed: {
      ...mapGetters({
        ListTournament: 'tournament/ListTournament',
        TournamentReward: 'tournament/TournamentReward',
      }),
      infoBox() {
        const arr = [
          {
            img: 'shop-1.png',
            title: 'Available',
            content: 'Unlimited',
          },
          {
            img: 'shop-2.png',
            title: 'Time Remaining',
            content: 'Unlimited',
          },
          {
            img: 'shop-3.png',
            title: 'Purchased',
            content: '100',
          },
        ];
        return arr;
      },
      boxList() {
        return listBox;
      },
    },
    methods: {
      ...mapMutations(['onLoad', 'outLoad']),
      openModalConfirmBuy(tournament) {
        this.onLoad();
        this.tournamentSelect = tournament;
        setTimeout(() => {
          this.$bvModal.show(`confirm-buy-${this.tournamentSelect.id}`);
          this.outLoad();
        }, 300);
      },
      onBuyTournament() {
        this.onLoad();
        this.$store.dispatch('tournament/req_postBuyTournament', {
          id: this.tournamentSelect.id,
        });
      },
      checkBuyedTicket(id) {
        console.log(id);
        for (
          let index = 0;
          index < this.ListTournament.tournaments_user.length;
          index++
        ) {
          if (
            this.ListTournament.tournaments_user[index].tournament.id === id
          ) {
            console.log(
              this.ListTournament.tournaments_user[index].tournament.id
            );
            return true;
          }
        }
        return false;
      },
      onOpenInventory() {
        this.$bvModal.show('user-inventory');
      },
      onSearchNameTicket(type) {
        if (type === 'reset') {
          this.name = '';
          this.$store.dispatch('tournament/req_getListTournament');
          return;
        }
        this.$store.dispatch('tournament/req_getListTournament', {
          name: this.name,
        });
      },
    },
    created() {
      this.$store.dispatch('tournament/req_getListTournament');
      this.unsubscribe = this.$store.subscribe((mutation, state) => {
        // eslint-disable-next-line default-case
        switch (mutation.type) {
          case 'tournament/BUY_TOURNAMENT_SUCCESS':
            this.$store.dispatch('tournament/req_getListTournament');
            this.$store.dispatch('dashboard/req_getDashboard', 1);
            this.$bvModal.hide(`confirm-buy-${this.tournamentSelect.id}`);
            setTimeout(() => {
              this.$bvModal.show('reward');
              this.outLoad();
            }, 200);

            break;
        }
      });
    },
    beforeDestroy() {
      this.unsubscribe();
    },
  };
</script>

<template>
  <b-container fluid class="shop-box">
    <b-container class="section section-1">
      <div class="page-name">The Ticket</div>
    </b-container>
    <b-container class="section section-2">
      <div class="sub-text">
        TICKET TO JOIN CASINO blackchip COMPETITION IN PHU QUOC
      </div>
    </b-container>
    <b-container class="section section-3" v-if="false">
      <b-row>
        <b-col cols="12" md="4" sm="6" v-for="info in infoBox" :key="info.img">
          <div class="box-info-shop">
            <div class="icons">
              <!-- <img :src="require(`@/assets/images/tour/${info.img}`)" alt="" /> -->
              <!-- <img src="~@/assets/images/luckyspin/ticket-img.png" alt="" /> -->
            </div>
            <div class="text">
              <div class="text-title">
                {{ info.title }}
              </div>
              <div class="text-content">
                {{ info.content }}
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="section section-4">
      <div class="inventory w-100 text-center mb-3">
        <b-button
          variant="warning"
          class="font-weight-bold text-uppercase"
          @click="onOpenInventory"
          >Inventory</b-button
        >
      </div>
      <div class="input-search text-center mb-4">
        <input
          type="text"
          placeholder="Input Name Ticket"
          class="form-control"
          v-model="name"
        />
        <div class="btn-group">
          <b-button variant="success" class="mx-2" @click="onSearchNameTicket"
            >Search</b-button
          >
          <b-button variant="danger" @click="onSearchNameTicket('reset')"
            >Reset</b-button
          >
        </div>
      </div>
      <VueSlickCarousel
        v-bind="settings"
        v-if="
          ListTournament &&
            ListTournament.tournaments &&
            ListTournament.tournaments.length > 0
        "
      >
        <div
          class="carousel-item-shop"
          v-for="tournament in ListTournament.tournaments"
          :key="tournament.id"
        >
          <div>
            <div class="box-container">
              <div class="box-img">
                <!-- <img :src="require(`@/assets/images/tour/${box.img}`)" alt="" /> -->
                <!-- <img :src="tournament.image" alt="" /> -->
              </div>
              <div class="box-content">
                <div class="name silver">
                  {{ tournament.name }}
                </div>
                <div class="buy-section">
                  <b-button class="price">
                    <div class="left">Price:</div>
                    <div class="right">
                      <div class="hbg">
                        {{ TruncateToDecimals2(+tournament.price, 0) }} EUSD
                      </div>
                    </div>
                    <!-- <div class="left">Ticket:</div>
                    <div class="right">
                      <div class="hbg">Active</div>
                    </div> -->
                  </b-button>
                </div>
                <div class="detail" v-html="tournament.description"></div>
                <b-button
                  class="buy"
                  @click="openModalConfirmBuy(tournament)"
                  :disabled="checkBuyedTicket(tournament.id)"
                >
                  {{ checkBuyedTicket(tournament.id) ? 'Activated' : 'Buy' }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </b-container>
    <!-- modal confirm buy -->
    <b-modal
      :id="`confirm-buy-${this.tournamentSelect.id}`"
      title="Confirm Buy Ticket"
      hide-footer
      modal-class="confirm-buy"
    >
      <div>
        <div class="circle text-center">
          <img :src="tournamentSelect.image" alt="" width="350px" height="auto"/>
        </div>
        <p class="mb-0 text-confirm">
          Do you want to use
          <span class="price"
            >{{
              TruncateToDecimals2(+this.tournamentSelect.price, 0)
            }}
            EUSD</span
          >
          to buy Ticket ?
        </p>
      </div>
      <div class="form-group d-flex justify-content-center">
        <b-button
          class="mx-auto mt-5 mb-0 button-confirm"
          @click="onBuyTournament"
        >
          Confirm
        </b-button>
      </div>
    </b-modal>
    <!-- inventory -->
    <b-modal
      id="user-inventory"
      title="INVENTORY"
      hide-footer
      modal-class="confirm-buy"
    >
      <div
        v-if="
          ListTournament &&
            ListTournament.tournaments_user &&
            ListTournament.tournaments_user.length > 0
        "
      >
        <div
          class="
            amount-ticket
            text-white text-center text-uppercase
            font-weight-bold
            mb-3
          "
        >
          Amount ticket:
          <span class="price">{{
            ListTournament.tournaments_user.length
          }}</span>
        </div>
        <VueSlickCarousel
          v-bind="settings"
          v-if="
            ListTournament &&
              ListTournament.tournaments_user &&
              ListTournament.tournaments_user.length > 0
          "
        >
          <div
            class="carousel-item-shop"
            v-for="tournament in ListTournament.tournaments_user"
            :key="tournament.id"
          >
            <div>
              <div class="box-container">
                <div class="box-img">
                  <!-- <img :src="require(`@/assets/images/tour/${box.img}`)" alt="" /> -->
                  <img :src="tournament.tournament.image" alt="" />
                </div>
                <div class="box-content">
                  <div class="name silver">
                    {{ tournament.tournament.name }}
                  </div>
                  <div class="buy-section">
                    <b-button class="price">
                      <div class="left">Price:</div>
                      <div class="right">
                        <div class="hbg">
                          {{
                            TruncateToDecimals2(+tournament.tournament.price, 0)
                          }}
                          EUSD
                        </div>
                      </div>
                      <!-- <div class="left">Ticket:</div>
                    <div class="right">
                      <div class="hbg">Active</div>
                    </div> -->
                    </b-button>
                  </div>
                  <div
                    class="detail"
                    v-html="tournament.tournament.description"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
      </div>
      <div v-else>
        <h3 class="text-white text-center text-uppercase font-weight-bold mb-3">
          You currently have 0 tickets.
        </h3>
        <p class="text-white text-center text-uppercase font-weight-bold mb-3">
          Please purchase additional tickets to participate in the ongoing
          tournament.
        </p>
      </div>
      <div class="form-group d-flex justify-content-center">
        <b-button
          class="mx-auto mt-5 mb-0 button-confirm"
          @click="$bvModal.hide('user-inventory')"
        >
          Confirm
        </b-button>
      </div>
    </b-modal>
    <!-- Modal reward -->
    <b-modal
      id="reward"
      title="Received Ticket"
      hide-footer
      v-if="Object.keys(TournamentReward).length > 0"
    >
      <div>
        <img src="" alt="" />
        <div class="circle text-center">
          <img :src="TournamentReward.list_ticket[0].tournament.image" alt="" />
        </div>
        <div class="reward-content text-white text-center">
          You buy success Ticket with name :
          <span class="price">{{
            TournamentReward.list_ticket[0].tournament.name
          }}</span>
        </div>
      </div>
      <div class="form-group d-flex justify-content-center">
        <b-button
          class="mx-auto mt-5 mb-0 button-confirm"
          @click="$bvModal.hide('reward')"
        >
          Confirm
        </b-button>
      </div>
    </b-modal>
  </b-container>
</template>

<style lang="scss">
  $box-color: linear-gradient(0deg, #02ffff -60%, #001326 100%);
  .shop-box {
    .section {
      padding: 50px 0;
      .page-name {
        position: relative;
        font-size: clamp(50px, 5vw, 85px);
        margin: auto;
        text-transform: uppercase;
        color: #fff;
        text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
      }
    }
    .section-1 {
      text-align: center;
      padding: 10px 15px 25px;
      img {
        width: 100%;
        max-width: 740px;
        height: auto;
      }
      @media (max-width: 480px) {
        widows: 100%;
        max-width: unset;
        padding: 15px 0px 15px;
      }
    }
    .section-2 {
      padding: 10px 15px;
      .sub-text {
        color: #6cb40f;
        font-size: clamp(1em, 4vw, 1.5em);
        max-width: 991px;
        line-height: 120%;
        margin: auto;
        text-align: center;
      }
      @media (max-width: 480px) {
        padding: 15px 15px;
        .sub-text {
          font-size: 1.1em;
        }
      }
    }
    .section-3 {
      .box-info-shop {
        color: #f5f6f7;
        background: rgba(33, 34, 34, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 140px;
        width: 100%;
        max-width: 360px;
        padding: 5% 10%;
        border-radius: 10px;
        margin: 15px auto;
        @media (max-width: 480px) {
          min-height: 100px;
        }
        .icons {
          flex: 0 0 70px;
          width: 70px;
          height: 100%;
          img {
            width: 50px;
            height: auto;
          }
        }
        .text {
          flex: 1 1 auto;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          .text-title {
            color: #6cb40f;
            font-size: 1.2em;
            line-height: 120%;
          }
          .text-content {
            color: #ffffff;
            font-size: 1.4em;
            line-height: 120%;
          }
        }
      }
    }
    .section-4 {
      .inventory {
        position: relative;
        z-index: 10;
      }
      .slick-arrow {
        z-index: 30;
        width: max-content;
        height: max-content;

        &.slick-next {
          right: -2%;
          &:before {
            content: '';
            background: url('~@/assets/images/tour/next.png');
            background-size: 100%100%;
            background-repeat: no-repeat;
            height: 40px;
            width: 25px;
            display: block;
          }
        }
        &.slick-prev {
          left: -2%;
          &:before {
            content: '';
            background: url('~@/assets/images/tour/prev.png');
            background-size: 100%100%;
            background-repeat: no-repeat;
            height: 40px;
            width: 25px;
            display: block;
          }
        }
        @media (min-width: 1366px) {
          &.slick-next {
            right: 0%;
          }
          &.slick-prev {
            left: 0%;
          }
        }
        @media (max-width: 425px) {
          &.slick-next,
          &.slick-prev {
            &:before {
              height: 36px;
              width: 25px;
            }
          }
          &.slick-next {
            right: -25px;
          }
          &.slick-prev {
            left: -25px;
          }
        }
      }
      .box-container {
        background: rgba(33, 34, 34, 1);
        width: 100%;
        max-width: 270px;
        margin: auto;
        border-radius: 15px;
        padding: 10px 0;
        .box-img {
          text-align: center;
          img {
            width: 70%;
            margin: 0 auto;
            filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
          }
        }

        .box-content {
          // background: {
          //   image: url("~@/assets/images/tour/content.png");
          //   size: 100%100%;
          //   position: bottom center;
          //   repeat: no-repeat;
          // }
          margin-top: 5px;
          width: 100%;
          min-height: 300px;
          padding: 15px 10% 0;
          .name {
            font-size: 1em;
            text-align: center;
            margin-bottom: 15px;

            text-transform: uppercase;
            &.diamond {
              color: #ff00ff;
              text-shadow: 1px 0px 9px #ff00ff;
            }
            &.gold {
              color: #f3ff00;
              text-shadow: 1px 0px 9px #f3ff00;
            }
            &.silver {
              color: #6cb40f;
              text-shadow: 1px 0px 9px #6cb40f;
            }
            &.platinum {
              color: #4effff;
              text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
              @media (max-width: 374px) {
                font-size: 1.7em;
              }
            }
          }
          .buy-section {
          }

          button {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #f5f6f7;
            padding: 5px 10px;
            border-radius: 25px;
            box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
            &.price {
              background-color: #ffa300;
              background-image: conic-gradient(from 1turn, #ffa20074, #ffa300);
            }
            &.buy {
              margin: 0 auto;
              max-width: 150px;
              background-color: #5da000;
              background-image: conic-gradient(from 1turn, #458802, #7bc514);
            }
            .left {
              flex: 0 0 25%;
              color: #ffff;
              font-weight: 600;
            }
            .right {
              flex: 0 0 75%;
              padding-left: 10px;
              color: #fff;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              .hbg {
                font-size: 1.4em;
                font-weight: 900;
                line-height: 120%;
              }
              .usd {
                font-size: 1em;
                font-weight: 900;
                margin-left: 10px;
              }
            }
          }

          .detail {
            color: #fff;
            padding: 15px 0;
            text-align: left;
          }
        }
      }
      .input-search {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 auto;
        .form-control {
          max-width: 400px;
          min-width: 300px;
          border: 1px solid rgb(123, 197, 20);
          background-color: rgba(65, 66, 67, 0.452);
          border-left: none;
          border-radius: 15px;
          color: rgb(212, 250, 77);
          @media (max-width: 767px) {
            margin-bottom: 20px;
          }
          &:focus {
            background: none;
            border: 1px solid rgb(123, 197, 20);
            outline: none;
            box-shadow: none;
            color: rgb(212, 250, 77);
          }
        }
        .btn-group {
          @media (max-width: 767px) {
            width: 100%;
            display: flex;
            justify-content: center;
          }
          button {
            width: 100%;
            max-width: 100px;
          }
        }
      }
      @media (max-width: 767px) {
        width: 100%;
        padding: 50px 10px;
        max-width: 100%;
      }
    }
  }
  .confirm-buy,
  #reward {
    .text-confirm {
      font-size: 1.2em;
      margin: 10px;
      color: #fff;
      text-align: center;

      p {
        margin-bottom: 5px;
      }
    }
    .modal-content {
      border-radius: 0;
      .modal-header {
        padding: 0 10px;
        color: #000;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 50px;
        width: 100%;
        color: #f5f6f7;
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        background-color: #5da000;
        background-image: conic-gradient(from 1turn, #458802, #7bc514);
        font-weight: bold;
        border: 0;
        .modal-title {
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          font-size: 1.7em;
        }
        .close {
          color: #000;
          opacity: 1;
          font-size: 1.8rem;
          font-weight: 900;
          font-family: fantasy;
          outline: none !important;
        }
      }
      .modal-body {
        margin-top: 0;
        background: #17181b;
      }
      .button-confirm {
        width: 100%;
        max-width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #f5f6f7;
        padding: 5px 10px;
        border-radius: 25px;
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        background-color: #5da000;
        background-image: conic-gradient(from 1turn, #458802, #7bc514);
      }
      .button-cancel {
        background: linear-gradient(90deg, #762525 0%, #f92323 100%);
        border-radius: 0;
        width: auto;
        padding: 5px 15px;
        height: 45px;
        color: #fff;
        font-weight: 600;
        text-transform: capitalize;
        min-width: 140px;
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 25px auto 10px;
        border: 1px solid;
      }
      input {
        color: #fff;
        font-weight: 600;
        letter-spacing: 1px;
        &::-webkit-input-placeholder {
          color: #fff;
          opacity: 0.6;
          text-align: center;
          font-weight: 500;
          font-size: 16px;
          letter-spacing: 1px;
        }
        height: 40px;
        border: 1px solid #00ffff;
        background: linear-gradient(180deg, #18274a 0%, #001326 100%);
        border-radius: 0;
        margin: 15px auto;
        width: 80%;
        min-width: 200px;
        &:focus,
        &:focus-within {
          color: #fff !important;
          background: #18274a;
        }
      }
    }
    .modal-dialog {
      margin: 20px auto;
      top: 90px;
      @media (max-height: 800px) {
        top: 0;
      }
    }
    .modal-backdrop {
      background-color: rgba($color: #000000, $alpha: 0.7);
    }
    .price {
      font-weight: bold;
      color: #ffa300;
    }
  }
  #user-inventory {
    .modal-dialog {
      margin-top: 20px;
      max-width: 600px;
    }
    .slick-arrow {
      z-index: 30;
      width: max-content;
      height: max-content;

      &.slick-next {
        right: -2%;
        &:before {
          content: '';
          background: url('~@/assets/images/tour/next.png');
          background-size: 100%100%;
          background-repeat: no-repeat;
          height: 40px;
          width: 25px;
          display: block;
        }
      }
      &.slick-prev {
        left: -2%;
        &:before {
          content: '';
          background: url('~@/assets/images/tour/prev.png');
          background-size: 100%100%;
          background-repeat: no-repeat;
          height: 40px;
          width: 25px;
          display: block;
        }
      }
      @media (min-width: 1366px) {
        &.slick-next {
          right: 0%;
        }
        &.slick-prev {
          left: 0%;
        }
      }
      @media (max-width: 425px) {
        &.slick-next,
        &.slick-prev {
          &:before {
            height: 36px;
            width: 25px;
          }
        }
        &.slick-next {
          right: -25px;
        }
        &.slick-prev {
          left: -25px;
        }
      }
    }
    .box-container {
      background: rgba(33, 34, 34, 1);
      width: 100%;
      max-width: 250px;
      margin: auto;
      border-radius: 15px;
      padding: 10px 0;
      .box-img {
        text-align: center;
        img {
          width: 70%;
          margin: 0 auto;
          filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
        }
      }

      .box-content {
        // background: {
        //   image: url("~@/assets/images/tour/content.png");
        //   size: 100%100%;
        //   position: bottom center;
        //   repeat: no-repeat;
        // }
        margin-top: 5px;
        width: 100%;
        min-height: 200px;
        padding: 15px 10% 0;
        .name {
          font-size: 1em;
          text-align: center;
          margin-bottom: 15px;

          text-transform: uppercase;
          &.diamond {
            color: #ff00ff;
            text-shadow: 1px 0px 9px #ff00ff;
          }
          &.gold {
            color: #f3ff00;
            text-shadow: 1px 0px 9px #f3ff00;
          }
          &.silver {
            color: #98d7e0;
            text-shadow: 1px 0px 9px #98d7e0;
          }
          &.platinum {
            color: #4effff;
            text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
            @media (max-width: 374px) {
              font-size: 1.7em;
            }
          }
        }
        .buy-section {
        }

        button {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #f5f6f7;
          padding: 5px 10px;
          border-radius: 25px;
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          &.price {
            background-color: #ffa300;
            background-image: conic-gradient(from 1turn, #ffa20074, #ffa300);
          }
          &.buy {
            margin: 0 auto;
            max-width: 150px;
            background-color: #5da000;
            background-image: conic-gradient(from 1turn, #458802, #7bc514);
          }
          .left {
            flex: 0 0 25%;
            color: #ffff;
            font-weight: 600;
          }
          .right {
            flex: 0 0 75%;
            padding-left: 10px;
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .hbg {
              font-size: 1.4em;
              font-weight: 900;
              line-height: 120%;
            }
            .usd {
              font-size: 1em;
              font-weight: 900;
              margin-left: 10px;
            }
          }
        }

        .detail {
          color: #fff;
          padding: 15px 0;
          text-align: center;
        }
      }
    }
  }
</style>
